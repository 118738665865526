import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"

const DynamicPage = ({ data }) => {
  const { contentSections, metadata } = data.strapi.page

  return (
    <>
      <Seo seo={metadata} />
      <Layout>
        <Sections sections={contentSections} />
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query DynamicPageQuery($id: ID!) {
    strapi {
      page(id: $id) {
        slug
        shortName
        metadata {
          metaTitle
          metaDescription
          structuredData
          faqData
          indiaAddress
          australiaAddress
          ukAddress
          shareImage {
            id
            mime
            url
          }
        }
        contentSections {
          ... on Strapi_ComponentSectionsBottomActions {
            __typename
            id
            title
            description
            buttons {
              id
              newTab
              text
              type
              url
            }
          }
          ... on Strapi_ComponentSectionsClientLogo {
            __typename
            id
            clientLogo {
              id
              title
              media {
                id
                mime
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, formats: WEBP)
                  }
                }
                alternativeText
              }
            }
          }
          ... on Strapi_ComponentSectionsHero {
            __typename
            id
            buttons {
              id
              newTab
              text
              type
              url
            }
            smallTextWithLink
            title
            description
            heroBackgroundImage {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsFeatureColumnsGroup {
            __typename
            id
            name
            content
            projectButton {
              id
              newTab
              text
              type
              url
            }
            features {
              description
              icon {
                id
                mime
                alternativeText
                url
              }
              id
              title
            }
            featureBackground {
              url
              alternativeText
              id
              mime
              name
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsFeatureRowsGroup {
            __typename
            id
            features {
              description
              id
              link {
                id
                newTab
                text
                url
              }
              media {
                id
                mime
                url
                alternativeText
              }
              title
              description
              backgroundImage {
                alternativeText
                mime
                name
                id
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      transformOptions: { fit: COVER }
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsTestimonialsGroup {
            __typename
            id
            description
            link {
              id
              newTab
              text
              url
            }
            logos {
              id
              title
              logo {
                id
                mime
                alternativeText
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            testimonials {
              id
              logo {
                id
                mime
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                alternativeText
              }
              picture {
                id
                mime
                url
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      transformOptions: { fit: CONTAIN }
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                alternativeText
              }

              text
              authorName
              authorTitle
              link
            }
            testimonialBackground {
              id
              mime
              name
              alternativeText
              url
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  fluid(maxWidth: 4000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
          }
          ... on Strapi_ComponentSectionsLargeVideo {
            __typename
            id
            description
            title
            poster {
              id
              mime
              alternativeText
              url
            }
            video {
              alternativeText
              url
            }
          }
          ... on Strapi_ComponentSectionsRichText {
            __typename
            id
            content
          }
          ... on Strapi_ComponentSectionsPricing {
            __typename
            id
            title
            plans {
              description
              features {
                id
                name
              }
              id
              isRecommended
              name
              price
              pricePeriod
            }
          }
          ... on Strapi_ComponentSectionsLeadForm {
            __typename
            id
            emailPlaceholder
            location
            submitButton {
              id
              text
              type
            }
            LeadBackgroundImage {
              url
              mime
              name
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FIXED
                    quality: 90
                    transformOptions: { fit: COVER }
                    placeholder: BLURRED
                  )
                }
              }
            }
            title
          }
          ... on Strapi_ComponentSectionsBusiness {
            __typename
            id
            banner {
              heading
              content
              slider {
                url
                name
                mime
                id
                alternativeText
                urlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      transformOptions: { fit: COVER }
                      layout: FIXED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsTrustBusiness {
            __typename
            id
            title
            description
            mainPoint {
              id
              item
              heading
              content
            }
            mainPointOne {
              id
              item
              heading
              content
            }
            media {
              url
              mime
              id
              alternativeText
            }
            picture {
              url
              mime
              id
              alternativeText
            }
          }
          ... on Strapi_ComponentSectionsProcess {
            __typename
            id
            title
            processButton {
              id
              newTab
              text
              type
              url
            }
            processBackgroundImage {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsContactForm {
            __typename
            id
            emailPlaceholder
            sendButton {
              id
              text
              type
            }
            contactBackgroundImage {
              url
              mime
              name
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FIXED
                    quality: 90
                    transformOptions: { fit: COVER }
                    placeholder: BLURRED
                  )
                }
              }
            }
            title
          }
          ... on Strapi_ComponentSectionsModalForm {
            __typename
            id
            submitButton {
              id
              text
              type
            }
            modalBackgroundImage {
              url
              mime
              name
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FIXED
                    quality: 90
                    transformOptions: { fit: COVER }
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsAboutUs {
            __typename
            id
            name
            founder {
              id
              mime
              alternativeText
              url
            }
            aboutButton {
              id
              text
              type
            }
            titleOne
            titleTwo
            descriptionOne
            aboutFeature {
              description
              id
              title
              media {
                id
                mime
                url
                alternativeText
              }
            }

            backgroundImage {
              alternativeText
              mime
              name
              id
              url
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    transformOptions: { fit: COVER }
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsSofyrusTechnologies {
            __typename
            id
            title
            newNeed {
              id
              sofyrusNewNeed {
                id
                listItem
              }
              title
              media {
                id
                mime
                name
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsCloudServices {
            __typename
            id
            title
            subheading
            content
            erpCard {
              id
              title
              content
              media {
                id
                mime
                name
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsAutomationServices {
            __typename
            id
            title
            description
            automationList {
              id
              title
              media {
                id
                mime
                name
                url
              }
              automationItem {
                id
                listItem
              }
            }
          }
          ... on Strapi_ComponentSectionsFaq {
            __typename
            id
            title
            faqSection {
              id
              question
              content
            }
          }
          ... on Strapi_ComponentSectionsCloudIndustries {
            __typename
            id
            title
            description
            ServicesHeading {
              id
              key
              heading
            }
            cloudProcess {
              id
              question
              content
              key
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsOurDevelopmentProcess {
            __typename
            id
            title
            description
            photo {
              url
              mime
              id
              name
              alternativeText
            }
            developmentProcess {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsEditorial {
            __typename
            id
            content
          }
          ... on Strapi_ComponentSectionsLinkServices {
            __typename
            id
            title
            description
            photo {
              url
              mime
              id
              name
              alternativeText
            }
            mainServices {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
            mainTechnologies {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
            mainOffer {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
            lowCode {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
            aiMl {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
            migration {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsTeamSection {
            __typename
            id
            title
            description
            teamMember {
              id
              name
              designation
              photo {
                name
                mime
                id
                url
                alternativeText
              }
              socialLink {
                id
                newTab
                text
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsEngineeringTeam {
            __typename
            id
            title
            teamEngineer {
              id
              title
              position
              photo {
                name
                mime
                id
                url
                alternativeText
              }
              socialLinkTeam {
                id
                newTab
                text
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsParaComponent {
            __typename
            id
            serviceFeatures {
              id
              title
              description
              icon {
                id
                mime
                alternativeText
                url
              }
              serviceLinks {
                id
                newTab
                text
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsPrivacyPolicy {
            __typename
            id
            content
          }
          ... on Strapi_ComponentSectionsNewSection {
            __typename
            id
            title
            dynamicModalHeading
            description
            brocButton {
              id
              newTab
              text
              type
              url
            }
            image {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            modalImageBackground_dynamic {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsOurDevelopmentServices {
            __typename
            id
            title
            description
            photo {
              url
              mime
              id
              name
              alternativeText
            }
            serviceDevelopment {
              id
              question
              content
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsLandingSection {
            __typename
            id
            title
            description
            keyPair {
              id
              key
              title
            }
            contentValue {
              id
              question
              content
              keyWords {
                id
                key
                title
              }
            }
          }
          ... on Strapi_ComponentSectionsHeadingComponent {
            __typename
            id
            heading
          }
          ... on Strapi_ComponentSectionsMyArticle {
            __typename
            id
            title
            latestArticle {
              id
              headingLink {
                id
                newTab
                text
                url
              }
              description
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsScale {
            __typename
            id
            title
            description
            scaleTeam {
              id
              knowMoreButton {
                id
                newTab
                text
                url
              }
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
          ... on Strapi_ComponentSectionsNewSectionTwo {
            __typename
            id
            title
            description
            dynamicModalHeading
            brocDownload {
              id
              newTab
              text
              type
              url
            }
            image {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            modalImageBackground_dynamic {
              url
              name
              mime
              id
              alternativeText
              urlSharp {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { fit: COVER }
                    layout: FIXED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsCategoryCard {
            __typename
            id
            title
            description
            categoriesCard {
              id
              heading
              content
              image {
                id
                mime
                name
                url
              }
            }
            bookDemo {
              id
              newTab
              text
              type
              url
            }
          }
          ... on Strapi_ComponentSectionsKnowAbout {
            __typename
            id
            title
            description
            knowAboutTeam {
              id
              heading
              content
              knowTeam {
                id
                newTab
                text
                url
              }
              image {
                id
                mime
                alternativeText
                url
              }
            }
          }
        }
      }
    }
  }
`
